import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';

import { generateNonce, verifySignedMessageForNetwork } from '@zealy/queries';
import { chainIdToNetwork } from '@zealy/utils';

import { toast } from '#components/Toaster';

// https://www.rainbowkit.com/docs/authentication
export const authenticationAdapter = (onSuccess?: () => void) =>
  createAuthenticationAdapter({
    getNonce: generateNonce,
    createMessage: ({ nonce, address, chainId }) => {
      return new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in to Zealy',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce,
      });
    },
    getMessageBody: ({ message }) => {
      return message.prepareMessage();
    },
    verify: async ({ message, signature }) => {
      try {
        await verifySignedMessageForNetwork(signature, message, chainIdToNetwork(message.chainId));
        onSuccess?.();
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        console.error('[AuthenticationAdapter:Verify] failed', e);
        return false;
      }
    },
    signOut: async () => {},
  });
